// ContactForm.js
import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Paper } from '@mui/material';
import Swal from 'sweetalert2';

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    telefono: '',
    comentario: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.nombre.trim()) newErrors.nombre = 'El nombre es requerido.';
    if (!formData.email.trim()) {
      newErrors.email = 'El email es requerido.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'El email no es válido.';
    }
    if (!formData.telefono.trim()) newErrors.telefono = 'El teléfono es requerido.';
    if (!formData.comentario.trim()) newErrors.comentario = 'El comentario es requerido.';
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formErrors = validate();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    // Aquí puedes implementar tu lógica de envío al backend/API
    console.log('Datos enviados:', formData);
    Swal.fire('¡Enviado!', 'Tu información fue enviada correctamente.', 'success');

    setFormData({ nombre: '', email: '', telefono: '', comentario: '' });
  };

  return (
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Formulario de Contacto
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
              Detalle su requerimiento con detalle en el campo comentario
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Para solicitar la eliminación de su cuenta y todos los datos asociados con la app móvil Hágalo en Casa,
              por favor diligencie el siguiente formulario. En su solicitud en el campo comentario
              indique claramente su deseo de eliminar su cuenta. Eliminaremos su información personal
              de nuestros servidores en un plazo de 30 días.  
            </Typography>

        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Nombre"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            margin="normal"
            error={!!errors.nombre}
            helperText={errors.nombre}
          />

          <TextField
            fullWidth
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            margin="normal"
            error={!!errors.email}
            helperText={errors.email}
          />

          <TextField
            fullWidth
            label="Teléfono"
            name="telefono"
            value={formData.telefono}
            onChange={handleChange}
            margin="normal"
            error={!!errors.telefono}
            helperText={errors.telefono}
          />

          <TextField
            fullWidth
            label="Comentario"
            name="comentario"
            multiline
            rows={4}
            value={formData.comentario}
            onChange={handleChange}
            margin="normal"
            error={!!errors.comentario}
            helperText={errors.comentario}
          />

          <Box sx={{ mt: 2, textAlign: 'right' }}>
            <Button type="submit" variant="contained" color="primary">
              Enviar
            </Button>
          </Box>
        </Box>
      </Paper>
  );
};
