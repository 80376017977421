import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { login } from '../actions/auth'
import { LoginScreen } from '../components/auth/LoginScreen'
import { RegisterScreen } from '../components/auth/RegisterScreen'
import { app } from '../firebase/firebaseConfig'
import { DashboardRoutes } from './DashboardRoutes'
import { ContactForm } from '../components/public/Contacto'
import { PrivateRoutes } from './PrivateRoutes'
import { PublicRoutes } from './PublicRoutes'

export const AppRouter = () => {

  const dispatch = useDispatch();

  const [checking, setChecking] = useState(true);
  const [isLoggedIn, setisLoggedIn] = useState(false);

  useEffect(() => {
    app.auth().onAuthStateChanged(     user => {
      if (user?.uid) {
        dispatch(login(user.uid, user.displayName, user.email));
        setisLoggedIn(true);

        /*const notes = loadNotes(user.uid);

        dispatch(setNotes(notes));*/

        
      }
      else{
        setisLoggedIn(false);
      }

      setChecking(false);
    })
  }, [dispatch, setChecking, setisLoggedIn])
  
  if(checking){
    return <div>Checking...</div>
  }


  return (
    <div>
      <Routes>
       
       <Route path='/auth/login' element={
         <PublicRoutes isLoggedIn={isLoggedIn} >  
           <LoginScreen />
         </PublicRoutes>
       } >
       </Route> 
 
 
       <Route path='/auth/register' element={
         <PublicRoutes isLoggedIn={isLoggedIn} >  
           <RegisterScreen />
         </PublicRoutes>
       } >
         
 
       </Route> 

       

       {/* <Route path='/respuesta' element={<Respuesta />} ></Route>
       <Route path="not-found" element={<NotFound />} />
       <Route path="*" element={<Navigate to="not-found" />} /> */}
 
        <Route path='/*' element={
           <PrivateRoutes isLoggedIn={isLoggedIn}>
               <DashboardRoutes />
           </PrivateRoutes>
         }>          
        </Route>  

        {/* Add route for Contacto */}
        <Route path='/contacto' element={
          <PublicRoutes isLoggedIn={isLoggedIn} >  
            <ContactForm />
          </PublicRoutes>
        } >
        </Route>
           
       </Routes>
    </div>
  )
}
